export const MIN_CHARS_SEARCH = 2; // Minimum characters on the search bar to allow the partner/showcase search

export const BROADCOM_PRIVACY_POLICY_URL =
  'https://www.broadcom.com/company/legal/privacy/policy';

export const COUNTRIES = [
  { name: 'United States', continent: 'Americas' },
  { name: 'United Kingdom', continent: 'Europe' },
  { name: 'Canada', continent: 'Americas' },
  { name: 'Bermuda', continent: 'Americas' },
  { name: 'Greenland', continent: 'Americas' },
  { name: 'Saint Pierre and Miquelon', continent: 'Americas' },
  { name: 'Anguilla', continent: 'Americas' },
  { name: 'Antigua and Barbuda', continent: 'Americas' },
  { name: 'Aruba', continent: 'Americas' },
  { name: 'Bahamas', continent: 'Americas' },
  { name: 'Barbados', continent: 'Americas' },
  { name: 'Bonaire [Sint Eustatius and Saba]', continent: 'Americas' },
  { name: 'Cayman Islands', continent: 'Americas' },
  { name: 'Curaçao', continent: 'Americas' },
  { name: 'Dominica', continent: 'Americas' },
  { name: 'Dominican Republic', continent: 'Americas' },
  { name: 'Grenada', continent: 'Americas' },
  { name: 'Guadeloupe', continent: 'Americas' },
  { name: 'Haiti', continent: 'Americas' },
  { name: 'Jamaica', continent: 'Americas' },
  { name: 'Martinique', continent: 'Americas' },
  { name: 'Montserrat', continent: 'Americas' },
  { name: 'Puerto Rico', continent: 'Americas' },
  { name: 'Saint Barthélemy', continent: 'Americas' },
  { name: 'Saint Kitts and Nevis', continent: 'Americas' },
  { name: 'Saint Lucia', continent: 'Americas' },
  { name: 'Saint Martin (French part)', continent: 'Americas' },
  { name: 'Saint Vincent and the Grenadines', continent: 'Americas' },
  { name: 'Sint Maarten (Dutch part)', continent: 'Americas' },
  { name: 'Trinidad and Tobago', continent: 'Americas' },
  { name: 'Turks and Caicos Islands', continent: 'Americas' },
  { name: 'Virgin Islands [British]', continent: 'Americas' },
  { name: 'Virgin Islands  [U.S.]', continent: 'Americas' },
  { name: 'Belize', continent: 'Americas' },
  { name: 'Costa Rica', continent: 'Americas' },
  { name: 'El Salvador', continent: 'Americas' },
  { name: 'Guatemala', continent: 'Americas' },
  { name: 'Honduras', continent: 'Americas' },
  { name: 'Mexico', continent: 'Americas' },
  { name: 'Nicaragua', continent: 'Americas' },
  { name: 'Panama', continent: 'Americas' },
  { name: 'Argentina', continent: 'Americas' },
  { name: 'Bolivia', continent: 'Americas' },
  { name: 'Bouvet Island', continent: 'Antarctica' },
  { name: 'Brazil', continent: 'Americas' },
  { name: 'Chile', continent: 'Americas' },
  { name: 'Colombia', continent: 'Americas' },
  { name: 'Ecuador', continent: 'Americas' },
  { name: 'Falkland Islands (Malvinas)', continent: 'Americas' },
  { name: 'French Guiana', continent: 'Americas' },
  { name: 'Guyana', continent: 'Americas' },
  { name: 'Paraguay', continent: 'Americas' },
  { name: 'Peru', continent: 'Americas' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    continent: 'Americas'
  },
  { name: 'Suriname', continent: 'Americas' },
  { name: 'Uruguay', continent: 'Americas' },
  { name: 'Venezuela [Bolivarian Republic of]', continent: 'Americas' },
  { name: 'Ãland Islands', continent: 'Europe' },
  { name: 'Denmark', continent: 'Europe' },
  { name: 'Estonia', continent: 'Europe' },
  { name: 'Faroe Islands', continent: 'Europe' },
  { name: 'Finland', continent: 'Europe' },
  { name: 'Iceland', continent: 'Europe' },
  { name: 'Ireland', continent: 'Europe' },
  { name: 'Isle of Man', continent: 'Europe' },
  { name: 'Latvia', continent: 'Europe' },
  { name: 'Lithuania', continent: 'Europe' },
  { name: 'Norway', continent: 'Europe' },
  { name: 'Svalbard and Jan Mayen', continent: 'Europe' },
  { name: 'Sweden', continent: 'Europe' },
  { name: 'Guernsey', continent: 'Europe' },
  { name: 'Jersey', continent: 'Europe' },
  { name: 'Austria', continent: 'Europe' },
  { name: 'Belgium', continent: 'Europe' },
  { name: 'France', continent: 'Europe' },
  { name: 'Germany', continent: 'Europe' },
  { name: 'Liechtenstein', continent: 'Europe' },
  { name: 'Luxembourg', continent: 'Europe' },
  { name: 'Monaco', continent: 'Europe' },
  { name: 'Netherlands', continent: 'Europe' },
  { name: 'Switzerland', continent: 'Europe' },
  { name: 'Albania', continent: 'Europe' },
  { name: 'Andorra', continent: 'Europe' },
  { name: 'Bosnia and Herzegovina', continent: 'Europe' },
  { name: 'Croatia', continent: 'Europe' },
  { name: 'Gibraltar', continent: 'Europe' },
  { name: 'Greece', continent: 'Europe' },
  { name: 'Holy See (Vatican City State)', continent: 'Europe' },
  { name: 'Italy', continent: 'Europe' },
  { name: 'Malta', continent: 'Europe' },
  { name: 'Montenegro', continent: 'Europe' },
  { name: 'Macedonia [the Former Yugoslav Republic of]', continent: 'Europe' },
  { name: 'Portugal', continent: 'Europe' },
  { name: 'San Marino', continent: 'Europe' },
  { name: 'Serbia', continent: 'Europe' },
  { name: 'Slovenia', continent: 'Europe' },
  { name: 'Spain', continent: 'Europe' },
  { name: 'Belarus', continent: 'Europe' },
  { name: 'Bulgaria', continent: 'Europe' },
  { name: 'Czech Republic', continent: 'Europe' },
  { name: 'Hungary', continent: 'Europe' },
  { name: 'Moldova [Republic of]', continent: 'Europe' },
  { name: 'Poland', continent: 'Europe' },
  { name: 'Romania', continent: 'Europe' },
  { name: 'Russian Federation', continent: 'Europe' },
  { name: 'Slovakia', continent: 'Europe' },
  { name: 'Ukraine', continent: 'Europe' },
  { name: 'Algeria', continent: 'Africa' },
  { name: 'Egypt', continent: 'Africa' },
  { name: 'Libya', continent: 'Africa' },
  { name: 'Morocco', continent: 'Africa' },
  { name: 'Sudan', continent: 'Africa' },
  { name: 'Tunisia', continent: 'Africa' },
  { name: 'Western Sahara', continent: 'Africa' },
  { name: 'British Indian Ocean Territory', continent: 'Asia' },
  { name: 'Burundi', continent: 'Africa' },
  { name: 'Comoros', continent: 'Africa' },
  { name: 'Djibouti', continent: 'Africa' },
  { name: 'Eritrea', continent: 'Africa' },
  { name: 'Ethiopia', continent: 'Africa' },
  { name: 'French Southern Territories', continent: 'Antarctica' },
  { name: 'Kenya', continent: 'Africa' },
  { name: 'Madagascar', continent: 'Africa' },
  { name: 'Malawi', continent: 'Africa' },
  { name: 'Mauritius', continent: 'Africa' },
  { name: 'Mayotte', continent: 'Africa' },
  { name: 'Mozambique', continent: 'Africa' },
  { name: 'Réunion', continent: 'Africa' },
  { name: 'Rwanda', continent: 'Africa' },
  { name: 'Seychelles', continent: 'Africa' },
  { name: 'Somalia', continent: 'Africa' },
  { name: 'South Sudan', continent: 'Africa' },
  { name: 'Tanzania [United Republic of]', continent: 'Africa' },
  { name: 'Uganda', continent: 'Africa' },
  { name: 'Zambia', continent: 'Africa' },
  { name: 'Zimbabwe', continent: 'Africa' },
  { name: 'Angola', continent: 'Africa' },
  { name: 'Cameroon', continent: 'Africa' },
  { name: 'Central African Republic', continent: 'Africa' },
  { name: 'Chad', continent: 'Africa' },
  { name: 'Congo', continent: 'Africa' },
  { name: 'Congo [the Democratic Republic of the]', continent: 'Africa' },
  { name: 'Equatorial Guinea', continent: 'Africa' },
  { name: 'Gabon', continent: 'Africa' },
  { name: 'Sao Tome and Principe', continent: 'Africa' },
  { name: 'Botswana', continent: 'Africa' },
  { name: 'Swaziland', continent: 'Africa' },
  { name: 'Lesotho', continent: 'Africa' },
  { name: 'Namibia', continent: 'Africa' },
  { name: 'South Africa', continent: 'Africa' },
  { name: 'Benin', continent: 'Africa' },
  { name: 'Burkina Faso', continent: 'Africa' },
  { name: 'Cape Verde', continent: 'Africa' },
  { name: "Côte d'Ivoire", continent: 'Africa' },
  { name: 'Gambia', continent: 'Africa' },
  { name: 'Ghana', continent: 'Africa' },
  { name: 'Guinea', continent: 'Africa' },
  { name: 'Guinea-Bissau', continent: 'Africa' },
  { name: 'Liberia', continent: 'Africa' },
  { name: 'Mali', continent: 'Africa' },
  { name: 'Mauritania', continent: 'Africa' },
  { name: 'Niger', continent: 'Africa' },
  { name: 'Nigeria', continent: 'Africa' },
  {
    name: 'Saint Helena [Ascension and Tristan da Cunha]',
    continent: 'Africa'
  },
  { name: 'Senegal', continent: 'Africa' },
  { name: 'Sierra Leone', continent: 'Africa' },
  { name: 'Togo', continent: 'Africa' },
  { name: 'China', continent: 'Asia' },
  { name: 'Hong Kong', continent: 'Asia' },
  { name: 'Japan', continent: 'Asia' },
  { name: 'Korea [Republic of]', continent: 'Asia' },
  { name: 'Macao', continent: 'Asia' },
  { name: 'Mongolia', continent: 'Asia' },
  { name: 'Taiwan [Province of China]', continent: 'Asia' },
  { name: 'Brunei Darussalam', continent: 'Asia' },
  { name: 'Cambodia', continent: 'Asia' },
  { name: 'Indonesia', continent: 'Asia' },
  { name: "Lao People's Democratic Republic", continent: 'Asia' },
  { name: 'Malaysia', continent: 'Asia' },
  { name: 'Myanmar', continent: 'Asia' },
  { name: 'Philippines', continent: 'Asia' },
  { name: 'Singapore', continent: 'Asia' },
  { name: 'Thailand', continent: 'Asia' },
  { name: 'Timor-Leste', continent: 'Asia' },
  { name: 'Viet Nam', continent: 'Asia' },
  { name: 'Afghanistan', continent: 'Asia' },
  { name: 'Bangladesh', continent: 'Asia' },
  { name: 'Bhutan', continent: 'Asia' },
  { name: 'India', continent: 'Asia' },
  { name: 'Maldives', continent: 'Asia' },
  { name: 'Nepal', continent: 'Asia' },
  { name: 'Pakistan', continent: 'Asia' },
  { name: 'Sri Lanka', continent: 'Asia' },
  { name: 'Armenia', continent: 'Asia' },
  { name: 'Azerbaijan', continent: 'Asia' },
  { name: 'Bahrain', continent: 'Asia' },
  { name: 'Cyprus', continent: 'Europe' },
  { name: 'Georgia', continent: 'Asia/Europe' },
  { name: 'Iraq', continent: 'Asia' },
  { name: 'Israel', continent: 'Asia' },
  { name: 'Jordan', continent: 'Asia' },
  { name: 'Kuwait', continent: 'Asia' },
  { name: 'Lebanon', continent: 'Asia' },
  { name: 'Oman', continent: 'Asia' },
  { name: 'Palestine [State of]', continent: 'Asia' },
  { name: 'Qatar', continent: 'Asia' },
  { name: 'Saudi Arabia', continent: 'Asia' },
  { name: 'Turkey', continent: 'Asia/Europe' },
  { name: 'United Arab Emirates', continent: 'Asia' },
  { name: 'Yemen', continent: 'Asia' },
  { name: 'Kazakhstan', continent: 'Asia' },
  { name: 'Kyrgyzstan', continent: 'Asia' },
  { name: 'Tajikistan', continent: 'Asia' },
  { name: 'Turkmenistan', continent: 'Asia' },
  { name: 'Uzbekistan', continent: 'Asia' },
  { name: 'Australia', continent: 'Oceania' },
  { name: 'Christmas Island', continent: 'Asia' },
  { name: 'Cocos (Keeling) Islands', continent: 'Asia' },
  { name: 'Heard Island and McDonald Islands', continent: 'Antarctica' },
  { name: 'New Zealand', continent: 'Oceania' },
  { name: 'Norfolk Island', continent: 'Oceania' },
  { name: 'Fiji', continent: 'Oceania' },
  { name: 'New Caledonia', continent: 'Oceania' },
  { name: 'Papua New Guinea', continent: 'Oceania' },
  { name: 'Solomon Islands', continent: 'Oceania' },
  { name: 'Vanuatu', continent: 'Oceania' },
  { name: 'Guam', continent: 'Oceania' },
  { name: 'Kiribati', continent: 'Oceania' },
  { name: 'Marshall Islands', continent: 'Oceania' },
  { name: 'Micronesia [Federated States of]', continent: 'Oceania' },
  { name: 'Nauru', continent: 'Oceania' },
  { name: 'Northern Mariana Islands', continent: 'Oceania' },
  { name: 'Palau', continent: 'Oceania' },
  { name: 'United States Minor Outlying Islands', continent: 'Americas' },
  { name: 'American Samoa', continent: 'Oceania' },
  { name: 'Cook Islands', continent: 'Oceania' },
  { name: 'French Polynesia', continent: 'Oceania' },
  { name: 'Niue', continent: 'Oceania' },
  { name: 'Pitcairn', continent: 'Oceania' },
  { name: 'Samoa', continent: 'Oceania' },
  { name: 'Tokelau', continent: 'Oceania' },
  { name: 'Tonga', continent: 'Oceania' },
  { name: 'Tuvalu', continent: 'Oceania' },
  { name: 'Wallis and Futuna', continent: 'Oceania' },
  { name: 'Antarctica', continent: 'Antarctica' },
  { name: 'Aland Islands', continent: 'Europe' }
];

export const getDayOfTheWeek = (t) => {
  return [
    { name: t('sunday'), short: t('sun') },
    { name: t('monday'), short: t('mon') },
    { name: t('tuesday'), short: t('tue') },
    { name: t('wednesday'), short: t('wed') },
    { name: t('thursday'), short: t('thu') },
    { name: t('friday'), short: t('fri') },
    { name: t('saturday'), short: t('sat') }
  ];
};

export const convertDayOfTheWeek = (day) => {
  const daysOfTheWeek = [
    { name: 'Sunday', short: 'Sun' },
    { name: 'Monday', short: 'Mon' },
    { name: 'Tuesday', short: 'Tue' },
    { name: 'Wednesday', short: 'Wed' },
    { name: 'Thursday', short: 'Thu' },
    { name: 'Friday', short: 'Fri' },
    { name: 'Saturday', short: 'Sat' }
  ];

  return daysOfTheWeek.find((e) => e.short.toLowerCase() === day.toLowerCase())
    ?.name;
};

export const getMonths = (t) => {
  return [
    { name: t('december'), number: '12', short: t('dec') },
    { name: t('november'), number: '11', short: t('nov') },
    { name: t('october'), number: '10', short: t('oct') },
    { name: t('september'), number: '09', short: t('sep') },
    { name: t('august'), number: '08', short: t('aug') },
    { name: t('july'), number: '07', short: t('jul') },
    { name: t('june'), number: '06', short: t('jun') },
    { name: t('may'), number: '05', short: t('may') },
    { name: t('april'), number: '04', short: t('apr') },
    { name: t('march'), number: '03', short: t('mar') },
    { name: t('february'), number: '02', short: t('feb') },
    { name: t('january'), number: '01', short: t('jan') }
  ];
};

export const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const vmWareProducts = [
  'VMware Essential (VSF)',
  'VMware vSphere Foundation (VVF)',
  'VMware Cloud Foundation (VCF)'
];

export const partnerTypeOptions = [
  'Aggregator 1.0',
  'Aggregator 2.0',
  'VMware Cloud Service Provider (VCSP)',
  'Consulting Services Partner',
  'Distributor (2 Tier)',
  'Distributor (3 Tier)',
  'Education & Training Partner',
  'Franchise/Emerging',
  'GSI/GSP',
  'Hyperscaler',
  'Marketplace (non CSP)',
  'MSP',
  'OEM',
  'Public Sector',
  'Reseller',
  'Tech Support Partner',
  'Telco',
  'VAD (CA Only)'
];
